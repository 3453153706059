import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import ColorSwatch from '../../../components/ColorSwatch';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
  Maintaining consistent and engaging digital interfaces throughout dv01, whether applications or experiences, demands extended guidance around color usage. The following concepts are the foundation as we strive to achieve balance and harmony through our User Interface design.
    </PageDescription>
    <h2>{`Introduction`}</h2>
    <p>{`Waterfall uses `}<em parentName="p">{`tokens`}</em>{` and `}<em parentName="p">{`themes`}</em>{` to manage color. Tokens are role-based, and themes specify the color values that serve those roles in the UI.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Theme`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The set of unique values assigned to the tokens of a Waterfall interface`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Token`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The code identifier for a unique role or set of roles. Tokens are universal and never change across themes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Role`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The systematic usage(s) of a token. Roles cannot be changed between themes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Value`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The actual style (i.e. hex code) assigned to a token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Tokens`}</h2>
    <p>{`Color in Waterfall is managed using color tokens. Tokens are a method of
abstracting color by role or usage, independent of the actual color values. For
example, instead of needing to code all instances of input labels in a UI to be
`}<inlineCode parentName="p">{`#855ffc`}</inlineCode>{`, the `}<em parentName="p">{`form`}</em>{` component in Waterfall specifies the token `}<inlineCode parentName="p">{`purple._50`}</inlineCode>{` for
these lables, and that token is specified in a separate theme file to be
`}<inlineCode parentName="p">{`#565656`}</inlineCode>{`. For a different theme, that same `}<inlineCode parentName="p">{`purple._50`}</inlineCode>{` token could be mapped to a
different hex value, like `}<inlineCode parentName="p">{`#ffffff`}</inlineCode>{`. Thus, tokens not only allow for more
efficient color updates within a theme, but also enable any UI (or portion of a
UI) built with Waterfall to easily switch between different themes.`}</p>
    <p>{`A single token can be associated with multiple roles, but only if the color
value is used consistently across those roles. This allows for uniform color
application across themes while maintaining full styling flexibility.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Using various forms of contrast is the most important consideration when making
user-friendly color and interface choices. Awareness of standards and best
practices is the key to accessible color selection.`}</p>
    <p>{`The color families in the Waterall palette contain ten values from 00 to 100. White
and Black sit outside those values. Black text is WCAG AA accessible on colors
ranging from 00 to 50. White text is accessible on colors from 60 to 100.`}</p>
    <ColorSwatch mdxType="ColorSwatch" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      